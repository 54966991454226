import CategoryService from "~/service/categoryService";

const SET_CATEGORY = "SET_CATEGORY";
const SET_CATEGORY_TREE = "SET_CATEGORY_TREE";
const SET_CATEGORY_MAP = "SET_CATEGORY_MAP";
const SET_FILTER_FACETS = "SET_FILTER_FACETS";
const SET_FILTER_FACETS_MOBILE = "SET_FILTER_FACETS_MOBILE";
const SET_FILTER_SORT = "SET_FILTER_SORT";
const SET_FILTER_PROMO = "SET_FILTER_PROMO";
const SET_FILTER_NEW = "SET_FILTER_NEW";
const SET_FILTER_TP = "SET_FILTER_TP";
const SET_FILTER_PREFERRED = "SET_FILTER_PREFERRED";
const SET_FILTER_PAGE = "SET_FILTER_PAGE";
const SET_FILTER_CATEGORY_ID = "SET_FILTER_CATEGORY_ID";
const RESET_FILTERS = "RESET_FILTERS";
const SET_PAGE_SIZE = "SET_PAGE_SIZE";
const SET_APPLICATION = "SET_APPLICATION";

const state = {
  category: {},
  application: {},
  categoryTree: new Array(),
  categoryMap: new Map(),
  filters: {
    facets: {},
    sort: null,
    promo: false,
    onlyNew: false,
    TP: null,
    preferred: false,
    page: 1,
    categoryId: null,
    pageSize: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE
  }
};

const getters = {
  categoryPath: state => {
    return getters.categoryPathById(state)(state.category.categoryId);
  },
  categoryPathById: state => categoryId => {
    const getBreadcrumbItem = function(category) {
      return {
        categoryId: category.categoryId,
        exact: true,
        link: true,
        text: category.name,
        to: { name: "Category", params: { pathMatch: category.slug } }
      };
    };

    function accumulate(category) {
      path.push(getBreadcrumbItem(category));
      if (category.parentId) {
        let parent = state.categoryMap.get(category.parentId);
        accumulate(parent);
      }
    }
    let path = [];

    let category = null;
    if (typeof state.categoryMap.get == "function") {
      category = state.categoryMap.get(categoryId);
    }
    if (category) {
      accumulate(category);
    } else {
      path.push(getBreadcrumbItem(state.category));
    }
    path.push({
      exact: true,
      link: true,
      text: "Homepage",
      to: { name: "Home" }
    });
    path = path.reverse();
    return path;
  },
  lookupCategory: state => categoryId => {
    function traverseToRoot(category) {
      if (category?.parentId && category.parentId > 0) {
        let parent = state.categoryMap.get(category.parentId);
        return traverseToRoot(parent);
      } else {
        return category;
      }
    }
    if (typeof state.categoryMap.get == "function") {
      let category = state.categoryMap.get(categoryId);
      return traverseToRoot(category);
    } else {
      return null;
    }
  },
  lookupCategoryPath: state => categoryId => {
    function addParent(category, categories) {
      let parent = state.categoryMap.get(category.parentId);
      categories.push(parent);
      if (parent.parentId) {
        addParent(parent, categories);
      }
    }
    let categories = [];
    if (typeof state.categoryMap.get == "function") {
      let category = state.categoryMap.get(categoryId);
      categories.push(category);
      if (category?.parentId) {
        addParent(category, categories);
      }
    }
    return categories.reverse();
  },
  findCategoryById: state => categoryId => {
    if (typeof state.categoryMap.get == "function") {
      let category = state.categoryMap.get(categoryId);
      return category;
    } else {
      return null;
    }
  },
  categoryBlocks: state => {
    return state.category && state.category.categoryBlocks
      ? state.category.categoryBlocks
      : null;
  },
  facetsFilter: state => {
    return state.filters.facets;
  },
  promoFilter: state => {
    return state.filters.promo;
  },
  newFilter: state => {
    return state.filters.onlyNew;
  },
  TPFilter: state => {
    return state.filters.TP;
  },
  preferredFilter: state => {
    return state.filters.preferred;
  },
  sortFilter: state => {
    return state.filters.sort;
  },
  pageFilter: state => {
    return state.filters.page;
  },
  pageSize: state => {
    return state.filters.pageSize;
  }
};

const actions = {
  async setCategory(
    { commit, state },
    {
      slug,
      force = false,
      filtered = global.config.filterEmptyCategory,
      query,
      skipCache,
      previewParams
    }
  ) {
    try {
      if (
        !state.category ||
        !(state.category.slug === slug || state.category.categoryId === slug) ||
        force === true ||
        force === "true"
      ) {
        let cat = await CategoryService.getCategoryBySlug(
          slug,
          filtered,
          query,
          skipCache,
          previewParams
        );
        commit(SET_CATEGORY, cat);
        // if (
        //   reset &&
        //   data.data.data.category.categoryId != state.filters.categoryId
        // ) {
        //   commit(SET_FILTER_CATEGORY_ID, data.data.data.category.categoryId);
        //   commit(RESET_FILTERS);
        // }
        return cat;
      } else {
        return state.category;
      }
    } catch (err) {
      commit(SET_CATEGORY, null);
      return null;
    }
  },
  async loadCategoryTree(
    { commit },
    filtered = global.config.filterEmptyCategory
  ) {
    function traverse(categories, parentId) {
      for (var key in categories) {
        var category = categories[key];
        category.parentId = parentId;
        categoryMap.set(category.categoryId, category);
        if (category.categories) {
          traverse(category.categories, category.categoryId);
        }
      }
    }
    let categoryMap = new Map();
    let categoryTree = [];
    try {
      categoryTree = await CategoryService.getCategoryTree(filtered);

      traverse(categoryTree);
    } catch (err) {
      if (err) {
        console.log(err);
      }
    }
    commit(SET_CATEGORY_TREE, categoryTree);
    commit(SET_CATEGORY_MAP, categoryMap);
  },
  setApplication({ commit }, application) {
    commit(SET_APPLICATION, application);
  },
  setFilterFacets({ commit }, { facets, parameterName }) {
    commit(SET_FILTER_FACETS, { facets, parameterName });
  },
  setFilterFacetsMobile({ commit }, { facets }) {
    commit(SET_FILTER_FACETS_MOBILE, facets);
  },
  setFilterSort({ commit }, sort) {
    commit(SET_FILTER_SORT, sort);
  },
  setFilterPromo({ commit }, promo) {
    commit(SET_FILTER_PROMO, promo);
  },
  setFilterNew({ commit }, onlyNew) {
    commit(SET_FILTER_NEW, onlyNew);
  },
  setFilterTp({ commit }, TP) {
    commit(SET_FILTER_TP, TP);
  },
  setFilterPreferred({ commit }, preferred) {
    commit(SET_FILTER_PREFERRED, preferred);
  },
  setFilterPage({ commit }, page) {
    commit(SET_FILTER_PAGE, page);
  },
  setPageSize({ commit }, pageSize) {
    commit(SET_PAGE_SIZE, pageSize);
  },

  resetFilters({ commit, state }, categoryId) {
    if (state.filters.categoryId != categoryId) {
      commit(RESET_FILTERS);
      commit(SET_FILTER_CATEGORY_ID, categoryId);
    }
  },
  resetCategory({ commit }) {
    commit(SET_CATEGORY, null);
    commit(RESET_FILTERS);
    commit(SET_FILTER_CATEGORY_ID, null);
  }
};

const mutations = {
  [SET_CATEGORY](state, value) {
    state.category = value;
  },
  [SET_CATEGORY_TREE](state, value) {
    state.categoryTree = value;
  },
  [SET_CATEGORY_MAP](state, value) {
    state.categoryMap = value;
  },
  [SET_APPLICATION](state, value) {
    state.application = value;
  },
  [SET_FILTER_FACETS](state, { facets, parameterName }) {
    state.filters.facets[parameterName] = facets;
    state.filters.page = 1;
  },
  [SET_FILTER_FACETS_MOBILE](state, facets) {
    state.filters.facets = facets;
    state.filters.page = 1;
  },
  [SET_FILTER_SORT](state, value) {
    state.filters.sort = value;
  },
  [SET_FILTER_PROMO](state, value) {
    state.filters.promo = value;
    state.filters.page = 1;
  },
  [SET_FILTER_NEW](state, value) {
    state.filters.onlyNew = value;
    state.filters.page = 1;
  },
  [SET_FILTER_TP](state, value) {
    state.filters.TP = value;
    state.filters.page = 1;
  },
  [SET_FILTER_PREFERRED](state, value) {
    state.filters.preferred = value;
    state.filters.page = 1;
  },
  [SET_FILTER_PAGE](state, value) {
    state.filters.page = value;
  },
  [SET_PAGE_SIZE](state, value) {
    state.filters.pageSize = value;
  },
  [SET_FILTER_CATEGORY_ID](state, value) {
    state.filters.categoryId = value;
  },
  [RESET_FILTERS](state) {
    state.filters = {
      facets: {},
      sort: null,
      promo: false,
      onlyNew: false,
      TP: null,
      preferred: false,
      page: 1,
      pageSize: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE
    };
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
